<template>
    <div id="visite" class="resize">
        <h3 class="center">{{info()}}</h3>
        <h3 class="center">Visite de Piste du {{ today | sdt }} à <input type="text" size="10" v-model="heure" />&nbsp;<button id="mybutton" @click="print()" ><i class="fa fa-print"></i>Imprimer</button></h3>
        <h3 class="center">Agent <input type="text" size="10" v-model="agent"> </h3>
        <h3 class="center">Commentaires : <br/><Textarea rows="8" cols="60" /></h3>

        <div class="pagebreak"> </div>

        <br/>
        <br/>
        <div id="map"></div>

        <div class="pagebreak"> </div>

        <table class="table" v-if="counta_today!=0">
      <thead>
        <tr>
          <th colspan="4">
            <h2>
              <span v-if="counta_today>1">Actions du {{ today | sdt }}</span><span v-if="counta_today==1">Action du {{ today | sdt }}</span>
            </h2>
          </th>
        </tr>
      </thead>

      <thead>
        <tr>
          <th scope="col" width="10%"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col" width="60%"><i class="fa fa-comment"></i> Action</th>
          <th scope="col" width="30%"><i class="fa fa-lightbulb-o"></i> Feu</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="action in actions">
        <tr :key="action.id"  v-if="action.dt==today">
          <td>{{ action.dt | sdt }}</td>
          <td>{{ action.Sujet }}</td>
          <td>{{ action.nomfeu }}</td>
        </tr>
    </template>
      </tbody>
    </table>
    <br/><br/>
    <table class="table" v-if="counta_before!=0">
      <thead>
        <tr>
          <th colspan="4">
            <h2>
                <span v-if="counta_before>1">Actions en cours</span><span v-if="counta_before==1">Action en cours</span>
            </h2>
          </th>
        </tr>
      </thead>

      <thead>
        <tr>
          <th scope="col" width="10%"><i class="fa fa-calendar"></i> Date</th>
          <th scope="col" width="60%"><i class="fa fa-comment"></i> Action</th>
          <th scope="col" width="30%"><i class="fa fa-lightbulb-o"></i> Feu</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="action in actions">
        <tr :key="action.id" v-if="action.dt!=today">
          <td>{{ action.dt | sdt }}</td>
          <td>{{ action.Sujet }}</td>
          <td>{{ action.nomfeu }}</td>
        </tr>
    </template>
      </tbody>
    </table>
    <br/><br/>
    </div>
</template>
  
<script>
import store from "../store";
import { imgApi, getPrefix, selectApi } from "../lib/api.js";
import { getPath, scaleColor, target } from "../lib/svg.js";
import { cvRotation } from "../lib/fct.js";
import Vue from "vue";
import "ol/ol.css";
import Map from 'ol/Map';
import { OSM, XYZ, Vector as VectorSource } from 'ol/source';
import { Icon, Style } from 'ol/style';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import View from 'ol/View';
import { transform } from 'ol/proj.js';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';

// --------------------- Layers --------------
// 0 Layer plan Visible
// 1 Layer action Visible
// --------------------- Layers --------------

let mark = false;

let action = [];
let markaction = null;
let vectorAction = null;

function setMarker(point, svg, id = 0) {
    let iconStyle = new Style({
        image: new Icon({
            opacity: 1,
            src: svg
        })
    });

    let feature = new Feature({
        geometry: new Point(transform([point.lng, point.lat], 'EPSG:4326', 'EPSG:3857'))
    });

    feature.id_ = id;
    feature.setStyle(iconStyle);
    return feature;
}

export default {
    name: "Map",
    store: store,
    data() {
        let dt = new Date()
        let tday = dt.toJSON().slice(0, 10)
        let hr = dt.getHours() + " h " + dt.getMinutes().toString().padStart(2, '0')
        return {
            map: null,
            mapCenter: { lat: 48.85840260089159, lng: 2.2944824899795937 },
            rotation: 0,
            count: 0,
            countc: 0,
            feux: [],
            typefeu: true,
            typeplan: true,
            nbactions: 0,
            osm: null,
            counta: 0,
            counta_today: 0,
            counta_before: 0,
            actions: [],
            today: tday,
            heure: hr,
            agent: ""
        };
    },
    computed: {
    },
    filters: {
        sdt: function (dt) {
            if (!dt) return "";
            return (
                dt.substring(8, 10) +
                "/" +
                dt.substring(5, 7) +
                "/" +
                dt.substring(0, 4)
            );
        },
    },
    methods: {
        xdt: function (dt) {
            if (!dt) return "";
            return (
                dt.substring(8, 10) +
                "-" +
                dt.substring(5, 7) +
                "-" +
                dt.substring(0, 4)
            )
        },
        print() {
            if (this.agent == "") {
                alert("Veuillez saisir le nom de l'agent")
                return
            }
            let save = document.title
            document.title = this.info() + " - " + this.agent + " - " + this.xdt(this.today) + " " + this.heure
            document.getElementById('myfooter').classList.add("hide")
            document.getElementById('mytopbar').classList.add("hide")
            document.getElementById('mybutton').classList.add("hide")

            window.print()

            document.title = save
            document.getElementById('myfooter').classList.remove("hide")
            document.getElementById('mytopbar').classList.remove("hide")
            document.getElementById('mybutton').classList.remove("hide")
        },
        info() {
            return store.state.nomUser
        },
        initMap() {
            this.osm = new TileLayer({
                source: new OSM()
            });

            vectorAction = new VectorSource({
                features: action,
            });

            markaction = new VectorLayer({
                source: vectorAction,
            });

            let view = null;
            let zoom = 15;
            let center = this.mapCenter;
            if (store.state.zoom != 0) {
                view = new View({
                    center: store.state.center,
                    zoom: store.state.zoom,
                    rotation: cvRotation(this.rotation)
                });
            } else {
                view = new View({
                    center: transform([center.lng, center.lat], 'EPSG:4326', 'EPSG:3857'),
                    zoom: zoom,
                    rotation: cvRotation(this.rotation)
                });
            }

            let map = new Map({
                layers: [this.osm, markaction],
                target: 'map',
                view: view,
            });

            this.map = map;
        },
        svg(code) {
            let path = getPath(code, 0, false);
            return path;
        },
        refreshActions() {
            let id = store.state.piste;
            let sql =
        "SELECT a.id,a.dt,a.Sujet,a.remarque,f.id as idfeu,f.nom as nomfeu,c.strcode,a.solde FROM actionpiste as a LEFT JOIN maintenance as m ON m.id=a.idmaintenance LEFT JOIN feu as f on f.id=a.idfeu LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur WHERE a.solde=0 and a.client=1 and m.idpiste=" +
        id +
        " ORDER BY a.dt DESC";
      selectApi(this, sql).then(
        (response) => {
          this.counta = response.data.count;
          this.counta_today = 0;
          this.counta_before = 0;
          this.actions = response.data.result;
          this.actions.forEach((action) => {
            action.dt = action.dt.substring(0,10)
            if (action.dt != this.today) {
                this.counta_before++}
                else {
                this.counta_today++
                }
            })
        },
        (response) => {
        }
      );
        },
        refreshFeux() {
            let id = store.state.piste;
            let sql =
                "SELECT f.id as idfeu,f.nom as nomfeu,f.latitude as flat,f.longitude as flng,r.id as idreg,r.nom as nomreg,c.libelle as couleur," +
                "c.strcode,p.latitude as plat,p.longitude as plng,f.statut,f.idfonction,f.idimage," +
                "(SELECT count(*) FROM mesurefeu WHERE idfeu=f.id) as nblux,p.rotation,f.idfonction,tf.libelle as typefeu,f.serigraphie,f.angle," +
                "(SELECT COUNT(*) FROM actionpiste WHERE solde=0 AND idfeu=f.id) AS nbaction," +
                "IF(tf.strcode2 is null,'FEU.png',tf.strcode2) as imgfeu," +
                "IF(tf.strcode3 is null,'CGB.pdf',tf.strcode3) as docfeu," +
                "r.couleur as regcouleur" +
                " FROM feu AS f" +
                " LEFT JOIN regulateur AS r ON r.id=f.idregulateur" +
                " LEFT JOIN cgbcommun.tableref AS c ON c.id=f.couleur" +
                " LEFT JOIN cgbcommun.tableref as tf ON tf.id=f.idtype" +
                " LEFT JOIN piste as p ON p.id=f.idpiste" +
                " WHERE f.idpiste=" + id;
            selectApi(this, sql).then(
                (response) => {
                    this.count = response.data.count;
                    this.feux = response.data.result;
                    if (this.count > 0) {
                        this.mapCenter.lat = this.feux[0].plat;
                        this.mapCenter.lng = this.feux[0].plng;
                        this.rotation = this.feux[0].rotation;
                        action = [];
                        this.maxaction = 0;

                        this.feux.forEach((feu) => {
                            if (feu.nbaction > this.maxaction) this.maxaction = feu.nbaction;
                        })

                        let point0 = { lat: window.myPos.lat, lng: window.myPos.lng };

                        const cible = target();
                        action.push(setMarker(point0, cible));
                        mark = true;

                        this.feux.forEach((feu) => {
                            feu.imgfeu = getPrefix() + "/img/" + feu.imgfeu;
                            feu.imgStatut = getPrefix() + "/img/" + (feu.statut == 0 ? "OK.png" : "HS.png");
                            let angle = feu.angle
                            if (angle == -1) angle = 0;

                            if (feu.flat != 0 && feu.flng != 0) {
                                let point = { lat: feu.flat, lng: feu.flng };

                                if (feu.nbaction > 0) {
                                    let path = getPath(scaleColor(feu.nbaction, this.maxaction), 0, feu.statut != 0);
                                    if (path != "") action.push(setMarker(point, path, feu.idfeu, feu.idfonction));
                                }
                            }
                        });

                        this.initMap();
                    }
                },
                (response) => {
                }
            );
        },
    },
    created() {
        window.EventBus = new Vue({
            data() {
                return {
                    id: 0,
                };
            },
        });
    },
    mounted() {
        if (store.state.typeUser == 0) {
            this.$router.push({ name: "login" });
        }
        this.refreshFeux();
        this.refreshActions();
    },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/design.scss";

.resize {
    width: 1024px;
    border: 1px solid black
}

#map {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    height: 75vh;
    display: block;
}

.center {
    text-align: center;
}

@media print {
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
}
</style>
